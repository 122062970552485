<template>
    <b-form-select v-model="model" :options="options" text-field="label"/>
</template>

<script>

export default {
    name: 'LabelGroupSelect',
    props: {
        value: {
            type: String,
        },
    },
    data() {
        return {
            labelGroups: [
                {
                    label: 'Этикетка контейнера',
                    value: 'pack_label',
                },
                {
                    label: 'Этикетка актов снабжения',
                    value: 'admission_act_label',
                },
                {
                    label: 'Этикетка актов перемещения',
                    value: 'material_transfer_act_label',
                },
                {
                    label: 'Этикетка сменных заданий',
                    value: 'production_task_label',
                },
            ],
        };
    },
    computed: {
        model: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            },
        },
        options() {
            return this.labelGroups;
        }
    },
}
</script>
