<template>
    <multi-select
        v-model="model"
        label="name"
        track-by="id"
        multiple
        :options="printers"
        show-labels
        close-on-select
        placeholder=""
        select-label=""
    />
</template>

<script>
import MultiSelect from 'vue-multiselect';
import {PRINTERS_ENDPOINT} from '@constants';

export default {
    name: 'PrinterSelect',
    props: {
        value: {
            type: Array,
        },
    },
    data() {
        return {
            printers: [],
        }
    },
    computed: {
        model: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            },
        },
    },
    components: {MultiSelect},
    methods: {
        load() {
            const params = {
                without_loading: true,
            };

            this.$http.get(PRINTERS_ENDPOINT, {params}).then((response) => this.printers = response.data.data);
        },
    },
    mounted() {
        this.load();
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
