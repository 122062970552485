<template>
    <b-container fluid>
        <errors-bag-list/>

        <b-card no-body class="mb-2 p-1">
            <div class="row">
                <b-col cols="3">
                    <b-button @click="create" class="btn btn-success">
                        <b-icon-plus scale="1.2"/>
                    </b-button>
                </b-col>
                <b-col cols="9" class="text-right">
                    <b-button variant="primary" @click="load" class="mr-1">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                </b-col>
            </div>
        </b-card>

        <b-table
            :items="items"
            :fields="fields"
            head-variant="dark"
            responsive
            hover
        >
            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button variant="warning" @click="edit(row.item)">
                        <b-icon-pencil-square/>
                    </b-button>
                    <b-button
                        v-show="!row.item.deleted_at"
                        variant="danger"
                        @click="onDelete(row.item)"
                    >
                        <b-icon-trash-fill/>
                    </b-button>
                    <b-button
                        v-show="row.item.deleted_at"
                        @click="onRestore(row.item)"
                        size="sm"
                        variant="light"
                    >
                        <b-icon-arrow-return-left/>
                    </b-button>
                </b-button-group>
            </template>
        </b-table>

        <b-modal
            :id="modal.id"
            :title="modal.title"
            @ok="onSubmit(form)"
            cancel-title="Отмена"
            cancel-variant="danger"
            ok-variant="success"
            ok-title="Сохранить"
            centered
        >
            <errors-bag-list/>
            <b-row>
                <b-col cols="12">
                    <b-form-group>
                        <template #label>
                            Наименование <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                        </template>
                        <b-form-input v-model.trim="form.data.name"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-form-group>
                        <template #label>
                            Наименование в Nicelabel <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                        </template>
                        <b-form-input v-model.trim="form.data.code"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-form-group>
                        <template #label>
                            Группа этикеток <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                        </template>
                        <label-group-select v-model="form.data.group"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-form-group>
                        <template #label>
                            Принтеры
                        </template>
                        <printer-select v-model="form.data.printers"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-form-checkbox v-model="form.data.has_custom_production_date">
                        Возможность указания даты производства
                    </b-form-checkbox>
                </b-col>
            </b-row>
        </b-modal>
    </b-container>
</template>

<script>

import ErrorsBagList from '@components/_common/ErrorsBagList';
import ErrorsBag from '@utils/errorsBag';
import {LABELS_ENDPOINT} from '@constants';
import LabelGroupSelect from './components/LabelGroupSelect.vue';
import PrinterSelect from './components/PrinterSelect.vue';

const lang = {
    pack_label: 'Этикетка контейнера',
    admission_act_label: 'Этикетка актов снабжения',
    material_transfer_act_label: 'Этикетка актов перемещения',
    production_task_label: 'Этикетка сменных заданий',
}

export default {
    name: 'IndexPage',
    components: {
        PrinterSelect,
        ErrorsBagList,
        LabelGroupSelect,
    },
    data() {
        return {
            ErrorsBag,
            items: [],
            fields: [
                {
                    key: 'id',
                    label: '№',
                },
                {
                    key: 'name',
                    label: 'Наименование',
                },
                {
                    key: 'code',
                    label: 'Наименование в NiceLabel',
                },
                {
                    key: 'group',
                    label: 'Наименование группы',
                    formatter: (value) => {
                        return lang[value];
                    }
                },
                {
                    key: 'actions',
                    label: 'Действия',
                },
            ],
            form: {
                data: {
                    name: null,
                    code: null,
                    group: null,
                    has_custom_production_date: false,
                    printers: [],
                },
                method: null,
                action: null,
            },
            modal: {
                id: 'label-form',
                title: '',
            },
        };
    },
    methods: {
        load() {
            ErrorsBag.discard();

            this.items = [];

            const params = {
                with: [
                    'printers',
                ],
                filters: {
                    with_trashed: true,
                }
            };

            this.$http
                .get(LABELS_ENDPOINT, {params})
                .then(response => {
                    this.items = response.data.data;
                });
        },
        create() {
            this.form.data = {
                name: null,
                code: null,
                group: null,
                has_custom_production_date: false,
                printers: [],
            };

            this.form.method = 'post';
            this.form.action = LABELS_ENDPOINT;

            this.modal.title = 'Добавление этикетки';
            this.$bvModal.show(this.modal.id);
        },
        edit(item) {
            this.form.data = structuredClone(item);
            this.form.method = 'put';
            this.modal.title = 'Редактирование этикетки';
            this.form.action = LABELS_ENDPOINT + '/' + item.id;

            this.$bvModal.show(this.modal.id);
        },
        onSubmit(form) {
            const data = {
                name: form.data.name,
                code: form.data.code,
                group: form.data.group,
                has_custom_production_date: form.data.has_custom_production_date,
                printer_ids: form.data.printers.map((printer) => printer.id),
            }

            this.$http[form.method](form.action, data).then(() => {
                this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                this.load();
            });
        },
        onDelete(item) {
            this.$bvModal.msgBoxConfirm(
                `Вы уверены, что хотите удалить этикетку "${item.name}"?`,
                {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    centered: true
                }
            ).then((value) => {
                if (value) {
                    ErrorsBag.discard();

                    this.$http.delete(LABELS_ENDPOINT + '/' + item.id).then(() => {
                        this.$bvToast.toast('Успешно удалено', {variant: 'success'});
                        this.load();
                    });
                }
            });
        },
        onRestore(item) {
            this.$bvModal.msgBoxConfirm(
                `Вы уверены, что хотите восстановить этикетку "${item.name}"?`,
                {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    centered: true
                }
            ).then((value) => {
                if (value) {
                }
            });
        }
    },
    mounted() {
        this.load();
    }
}
</script>
